import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"
import { Container, Row, Col } from "reactstrap"

import { yellow, lightGray, black, white } from "../utils/colors"

import Header from "../components/header"
import Footer from "../components/footer"
import MainImage from "../images/main_image@2x.jpg"
import UXImage from "../images/ux_image.png"
import MVPImage from "../images/mvp_image.png"
import GrowthImage from "../images/growth_image.png"
import ProcessImage from "../images/proccess.png"


const way = css({
    '& h2': {
        fontSize: 34,
        margin: "1rem 0 2rem"
    },
    '& p': {
        fontSize: 14,
        fontWeight: "bold",
        margin: "1rem 0 1.5rem"
    },
    '& ul': {
        textAlign: "center",
        display: "inline-block",
        maxWidth: 420,
        '& li': {
            fontSize: 14,
            display: "inline-block",
            backgroundColor: black,
            color: white,
            margin: "0.4rem auto",
            padding: "0.2rem 0.5rem"
        },
    },
})

const solution = css({
    '& h2': {
        fontSize: 14,
        lineHeight: 1.2,
        marginBottom: "0.8rem"
    },
    '& img': {
        margin: "8px 0"
    },
    '& .sub-title': {
        display: "block",
        fontSize: 24,
        fontWeight: "bold",
        lineHeight: 1.2,
        marginBottom: "1rem"
    }
})

const philosophy = css({
    '& h2': {
        fontSize: 34,
        margin: "2rem 0 2rem",
    },
    '& h3': {
        margin: "3rem 0 1rem",
        padding: "0.3rem",
        fontSize: 16,
        backgroundColor: "#7512F3",
        color: "white",
        '& span': {
            fontSize: 23,
            marginRight: "1rem"
        }
    },
    '& p': {
        margin: "1rem 0 2rem",
        fontSize: 14,
        '& b': {
            fontSize: 16
        },
    },
    '& img': {
        margin: "1rem 0",
        maxWidth: 800
    },
})

const about = css({
    backgroundColor: black,
    color: white,
    '& h2': {
        fontSize: 34,
        padding: "0 0 1rem",
    },
    '& h3': {
        fontSize: 24,
        borderBottom: "solid 1px " + "#7512F3",
        display: "inline-block",
        margin: "2rem auto 1rem",
        padding: "0 0.5rem 0.5rem"
    },
    '& h4': {
        fontSize: 18,
        margin: "1rem auto",
    },
    '& p': {
        margin: "1rem 0 2rem",
        fontSize: 14
    },
    '& img': {
        margin: "1rem auto",
        display: "block",
        maxWidth: 800
    },
})

const map = css ({
    height: 460
})

const solutionYellow = [
    solution, {
        backgroundColor: "#7512F3",
        color: white
    }
]
const solutionGray = [
    solution, {
        backgroundColor: lightGray
    }
]
const infoNumber = css({
    fontSize: 38
})

const mainMessage = css({
    position: "absolute",
    bottom: -16,
    left: "50%",
    width: 860,
    textAlign: "center",
    transform: "translateY(-50%) translateX(-50%)",
    "@media(max-width: 639px)": {
        position: "relative",
        transform: "none",
        left: 0,
        width: "auto",
        backgroundColor: black,
        padding: "16px 32px",
    },
    "& span": {
        margin: "1rem auto",
        padding: "0 0.8rem",
        fontSize: 36,
        fontWeight: "normal",
        lineHeight: "1.5em",
        backgroundColor: black,
        color: white,
        fontFamily: "'Halant', serif",
        "@media(max-width: 639px)": {
            fontSize: 24,
            color: white,
        },
    },
    "& h1": {
        margin: "1rem auto",
        padding: "0.2rem 0.5rem",
        display: "inline-block",
        fontSize: 16,
        fontWeight: "normal",
        lineHeight: "1.5em",
        backgroundColor: black,
        color: white,
        "@media(max-width: 639px)": {
            fontSize: 12,
            color: white,
        },
    }
})


export default class Index extends React.Component {
    render() {
        const { data } = this.props
        const { title, description, siteUrl, siteName } = data.site.siteMetadata
        return (
            <div>
                <Helmet>
                    <meta name="description" content={description} />
                    <title>{title}</title>
                    <link rel="canonical" href={siteUrl} />
                </Helmet>

                <Header/>

                <div css={css`
                    position: relative;
                    text-align: center;
                `}>
                    <img className="w-100 m-0 p-0" src={MainImage}/>
                    <div css={mainMessage}>
                        <span>We design business value. fast and certainly.</span>
                        <h1>我々はビジネスの価値をデザインする「デザイン・カンパニー」です。</h1>
                    </div>
                </div>

                <Container>
                    <Row>
                        <Col css={way} className="text-center px-lg-5 px-md-3 py-5">
                            <h2 className="halant">Our way is <b>“Prototyping”</b></h2>
                            <p>我々は「ビジネス価値のプロトタイプ」をデザインし、皆様にご提供いたします。<br/>
                            プロトタイプとは、必ずしも「目に見えるもの」だけではありません。</p>
                            <ul>
                                <li>自社内新規事業の定義をしたい</li>
                                <li>世の中の「家を借りる」という仕組みを変えたい</li>
                                <li>UXの概念を社内に浸透させたい</li>
                                <li>新しい保険の価値を創りたい</li>
                            </ul>
                            <p>こういった抽象度の高いオーダーに対し、ミッションを戦略的に整理し、検証すべきポイントを数学的に策定し、目的に合ったプロトタイプを高速で創り、猛然と検証する。</p>
                            <p>まだ見ぬイノベーションの姿をスピード感をもって具現化していく手法がLOOPの考えるプロトタイピングなのです。</p>
                        </Col>
                    </Row>
                </Container>

                <Container fluid>
                    <Row>
                        <Col css={solutionYellow} className="px-lg-5 px-md-3 px-sm-3 py-3" xs={12} sm={6} md={4}>
                            <div css={infoNumber} className="halant text-center">01</div>
                            <img className="w-100" src={UXImage} alt="UX Image"/>
                            <h2>UX/UIデザインコンサルティング</h2>
                            <span className="sub-title halant">UX / UI design</span>
                            <p>ユーザー課題の抽出から、精査・検証を通してビジネスの目的を明確にした上で、その課題を解決することに特化したユーザー体験の設計と、それに伴うユーザーインターフェイスのデザインをご提供します。</p>
                        </Col>
                        <Col css={solutionGray} className="px-lg-5 px-md-3 px-sm-3 py-3" xs={12} sm={6} md={4}>
                            <div css={infoNumber} className="halant text-center">02</div>
                            <img className="w-100" src={MVPImage} alt="MVP Image"/>
                            <h2>MVP 開発</h2>
                            <span className="sub-title halant">MVP development</span>
                            <p>ビジネスの価値だけに特化したMVP (Minimum value product) を最短の工数で開発し、プロダクトマーケットフィットのための土台をご提供いたします。</p>
                        </Col>
                        <Col css={solutionYellow} className="px-lg-5 px-md-3 px-sm-3 py-3" xs={12} sm={6} md={4}>
                            <div css={infoNumber} className="halant text-center">03</div>
                            <img className="w-100" src={GrowthImage} alt="GrowthHack Image"/>
                            <h2>グロースハック</h2>
                            <span className="sub-title halant">Growth hack</span>
                            <p>種であるMVPを成長させ、ビジネスの価値を最大化するためのチームビルディング・戦略デザインをご提供いたします。</p>
                        </Col>
                    </Row>
                </Container>

                <Container css={philosophy}>
                    <Row>
                        <Col className="text-center px-lg-5 px-md-3 py-5">
                            <h2 className="halant">Our philosophy is <b>“Fast &amp; Sertainly”</b></h2>
                            <p><b>我々は「速さ」と「確実さ」を軸に課題を解決します。</b></p>
                            <h3><span className="halant">01. Issue driven design</span>ユーザー目線でのデザイン</h3>
                            <p className="text-left">「解決すべきユーザーの課題」を明確にし、そこにフォーカスすることで確実にゴールに近づくことのできるデザインをご提供可能です。</p>
                            <h3><span className="halant">02. All through development</span>一気通貫の開発手法</h3>
                            <img src={ProcessImage} className="w-100" alt="一気通貫のプロセス"/>
                            <p className="text-left">「戦略」から「デザイン」や「開発」までを一気通貫に行うことで、コミュニケーションによるロスを最小化したプロダクトを提供いたします。</p>
                        </Col>
                    </Row>
                </Container>

                <Container fluid css={about} className="px-0">
                    <Container>
                        <Row>
                            <Col className="text-center px-lg-5 px-md-3 py-5">
                                <h2 className="halant">About us</h2>
                                <p className="text-left">LOOPは、新規事業およびサービス開発のプロトタイピングを専門とするテクノロジー企業として、リクルートホールディングス、サイバーエージェント、スタートアップファウンダーなど、事業開発バックグラウンドを持つスペシャリストにより、2020年に設立されました。</p>
                                <p className="text-left">デジタル化により事業環境がかつてないスピードで変化する現在、新しい価値を生み出すには、「Value Proposition の素早い検証（プロトタイピング）」と「テクノロジーへの深い理解」が重要です。私たちはインターネット黎明期より、大企業からベンチャーに至るまで、様々な業界の組織内外で新規事業・サービス開発に関わり、一貫して「プロトタイプ × テクノロジー」というキーワードの元、その開発手法を磨き続けてきました。</p>
                                <p className="text-left">私たちLOOPはクライアントのみなさまが、スピーディーに考え、行動し、めざましい結果を出せるよう、並走、支援してまいります。</p>

                                <h3 className="halant">Company</h3>
                                <h4>株式会社LOOP</h4>
                                <p className="halant">LOOP Inc.</p>

                                <h3 className="halant">Founder / CEO</h3>
                                <h4>平井 心</h4>
                                <p className="halant">Kokoro Hirai</p>

                                <h3 className="halant">Office</h3>
                                <p>〒150-0012 渋谷区広尾4-1-5-102</p>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Footer/>
            </div>
        )
    }
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title,
        description,
        siteUrl
      }
    }
  }
`
